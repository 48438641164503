
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    // import { useEffect } from 'react';
import { useMemo } from 'react';
import isString from 'lodash/isString';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import NextHead from 'next/head';
// import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';
import { FontFaces } from '@common/atoms/FontFaces';
import { PlatformID } from '@common/clients/api';
import { ContextData, ContextHash, decodeContextHash, isContextData, isContextHash, ResolvedContextData, } from '@common/defaults';
import { logger } from '@common/logger';
import { getHostByHostname, getPlatformIDByHostname, Host } from '@common/utils/HostUtil';
import { OrganizationStructuredData } from '@web/molecules/StructuredData';
import { sendGAEvent, TrackingHandler } from '@web/molecules/TrackingHandler';
import { Body } from '@web/templates/RootLayout/atoms/Body';
import '@common/logger/browser';
import CustomError from './_error';
import '../styles/index.scss';
const isDevelopment = process.env.APP_ENV === 'development';
function MyApp({ Component, pageProps: props, router }: AppProps) {
    const isServerSide = typeof window === 'undefined' || window.navigator.appVersion === 'undefined';
    const contextData: ContextData | undefined = isContextData(props?.contextData)
        ? props.contextData
        : undefined;
    const contextHash: ContextHash | undefined = isContextHash(props?.contextHash)
        ? props.contextHash
        : isString(router.query?.contextHash)
            ? decodeContextHash(router.query.contextHash)
            : undefined;
    let origin = contextData ? contextData.hostname : '';
    logger.registerAdditionalContext({
        locale: router.locale,
        pathname: router.asPath,
        route: router.route
    });
    const shouldShowErrorPage = Boolean(props.errorCode) && !isDevelopment;
    if (!isServerSide && (!origin || origin.length)) {
        origin = window?.location?.hostname ?? '';
    }
    const platformID = getPlatformIDByHostname(origin);
    const theme = useMemo(() => {
        if (router.route === '/liveness')
            return 'vp';
        else if (getHostByHostname(origin) === Host.VP_BE)
            return 'vpbe';
        else if (platformID)
            return platformID;
        else if (process.env.NEXT_PUBLIC_THEME)
            return process.env.NEXT_PUBLIC_THEME;
        else if (contextData)
            return contextData.platform.id;
        else if (process.env.APP_ENV === 'development' || process.env.CI === 'true')
            return 'vp';
        else {
            logger.warn(`Theme was not found for origin '${origin}'. Defaulting to 'vp'.`);
            return 'vp';
        }
    }, [origin, platformID, contextData, router.route]);
    return contextData && contextHash ? (shouldShowErrorPage ? (<CustomError {...props}/>) : (<>
                <TrackingHandler />
                <Body contextHash={contextHash} contextData={contextData as ResolvedContextData}>
                    <Component {...props}/>
                </Body>
                {contextData?.context ? <OrganizationStructuredData /> : null}
            </>)) : (<ThemeProvider forcedTheme={theme}>
            <FontFaces platform={platformID || PlatformID.VP}/>
            <NextHead>
                <meta name="robots" content="noindex, nofollow"/>
            </NextHead>
            {shouldShowErrorPage ? <CustomError {...props}/> : <Component {...props}/>}
        </ThemeProvider>);
}
export function reportWebVitals(metric: NextWebVitalsMetric) {
    logger.debug(metric);
    sendGAEvent('event', metric.name, {
        event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
        event_label: metric.id,
        non_interaction: true
    });
}
const __Next_Translate__Page__193bbb9ce43__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__193bbb9ce43__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  